import React, { Fragment } from 'react';

import { Home } from '@components/Home';
import { Meta } from '@components/Meta';
import Helmet from 'react-helmet';
import { Layout } from '@components/Layout';

export type HomeProps = {
  siteText: SiteText[];
  images: CmsHomepageImageEntry[];
} & BasePageProps;

type Props = { pageContext: HomeProps };

export default ({
  pageContext: { homepageFonts, siteText, images },
}: Props) => (
  <Fragment>
    <Helmet>
      <style type="text/css">{`body {
          overflow: hidden;
        }`}</style>
    </Helmet>

    <Meta />

    <Layout homepageFonts={homepageFonts}>
      <Home homepageFonts={homepageFonts} siteText={siteText} images={images} />
    </Layout>
  </Fragment>
);
