import React, { FC, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { pxToVw, adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import styled from 'styled-components';
import { isMobile, mobileMQ } from '@config/mediaQueries';

const Container = styled.div(
  adaptForLargeScreen({
    position: 'relative',
  }),
);

const Controls = styled.div(
  adaptForLargeScreen({
    position: 'absolute',
    left: 28,
    bottom: 34,
    display: 'flex',
    alignItems: 'center',
  }),
);

const PlayButton = styled.div(
  adaptForLargeScreen({
    width: 31,
    height: 31,
    borderRadius: 31,
    backgroundColor: 'white',
    cursor: 'pointer',
    marginRight: 15.5,
  }),
);

const PlayIcon = styled.div(
  adaptForLargeScreen({
    width: 0,
    height: 0,
    borderTopWidth: 14 / 2,
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    borderBottomWidth: 14 / 2,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderLeftWidth: 12,
    borderLeftStyle: 'solid',
    borderLeftColor: '#000',
    marginLeft: 11,
    marginTop: 9,
  }),
);

const PauseContainer = styled.div(
  adaptForLargeScreen({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  }),
);

const PauseLine = styled.div(
  adaptForLargeScreen({
    height: 12,
    width: 0,
    borderWidth: 1.5,
    borderColor: '#000',
    borderStyle: 'solid',
    marginLeft: 2,
    marginRight: 2,
  }),
);

const ProgressContainer = styled.div(
  adaptForLargeScreen({
    cursor: 'pointer',
    height: 24,
    display: 'flex',
    alignItems: 'center',
  }),
);

const ProgressBar = styled.div(
  adaptForLargeScreen({
    width: 844,
    marginRight: 18,
    height: 0,
    borderWidth: 1,
    borderColor: '#fff',
    borderStyle: 'solid',
    position: 'relative',

    [mobileMQ]: {
      width: '59vw',
    },
  }),
);

const ProgressLine = styled.div<{ progress: number }>(p =>
  adaptForLargeScreen({
    position: 'absolute',
    height: 24,
    width: 0,
    borderLeftWidth: 4,
    borderLeftColor: 'white',
    borderLeftStyle: 'solid',
    marginTop: -12,
    left: Math.round(p.progress * 844),

    [mobileMQ]: {
      left: `calc(58vw * ${p.progress})`,
    },
  }),
);

const FullScreenButton = styled.div(
  adaptForLargeScreen({
    cursor: 'pointer',
    width: 32,
    height: 32,
    backgroundColor: '#fff',
  }),
);

type Props = {
  url: string;
};

export const VideoPlayer: FC<Props> = props => {
  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = useState(true);

  const videoRef = useRef(null as ReactPlayer);
  const progressContainerRef = useRef(null as HTMLDivElement);

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const progressRect = progressContainerRef.current.getBoundingClientRect();
    const progress =
      (e.nativeEvent.pageX - progressRect.left) / progressRect.width;

    videoRef.current.seekTo(progress);
  };

  return (
    <Container>
      <ReactPlayer
        url={props.url}
        ref={videoRef}
        onProgress={p => setProgress(p.played)}
        progressInterval={30}
        playing={playing}
        width={isMobile() ? '100%' : pxToVw(1004)}
        height={isMobile() ? (565 / 1004) * window.innerWidth : pxToVw(565)}
        style={{ width: '100%', height: '100%' }}
        playsinline
      />

      <Controls>
        <PlayButton
          onClick={() => setPlaying(!playing)}
          onTouchStart={e => e.stopPropagation()}
        >
          {playing ? (
            <PauseContainer>
              <PauseLine />
              <PauseLine />
            </PauseContainer>
          ) : (
            <PlayIcon />
          )}
        </PlayButton>

        <ProgressContainer
          ref={progressContainerRef}
          onClick={handleProgressClick}
          onTouchStart={handleProgressClick as any}
          onTouchMove={handleProgressClick as any}
        >
          <ProgressBar>
            <ProgressLine progress={progress} />
          </ProgressBar>
        </ProgressContainer>

        <FullScreenButton
          onTouchStart={e => e.stopPropagation()}
          onClick={() => {
            const video = videoRef.current.getInternalPlayer() as HTMLVideoElement;
            if (video.webkitEnterFullScreen) video.webkitEnterFullScreen();
            else video.requestFullscreen();
          }}
        />
      </Controls>
    </Container>
  );
};
