import React, { useState, useLayoutEffect, FC } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import FontFaceObserver from 'fontfaceobserver';

import { getSiteTextFactory } from '@utils/siteText';
import UIStore from '@stores/UIStore';
import { SITE_PADDING_LEFT, SITE_PADDING_LEFT_MOBILE } from '@config/layout';
import {
  mobileMQ,
  isMobile,
  smallMobileMQ,
  tinyMobileMQ,
} from '@config/mediaQueries';
import { adaptForLargeScreen, pxToVw } from '@utils/adaptForLargeScreen';
import { StyledMarkdown } from './StyledMarkdown';
import { CONTENT_URL } from '@config/urls';
import { VideoPlayer } from './VideoPlayer';
import { ResponsiveImage } from './ResponsiveImage';
import { HomeProps } from 'src/templates';

const IMAGE_SCALE = 0.6;

const Container = styled.div({
  height: '100%',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  userSelect: 'none',

  position: 'absolute',
  top: 0,
  left: 0,
});

const FontDetails = styled.div(
  adaptForLargeScreen({
    marginLeft: SITE_PADDING_LEFT,
    marginRight: SITE_PADDING_LEFT,
    lineHeight: 1.333,
    marginBottom: 10,

    a: {
      textDecoration: 'underline',
    },

    [mobileMQ]: {
      marginLeft: SITE_PADDING_LEFT_MOBILE,
    },
  }),
);

const Text = styled.div<{
  font: string;
  fontSizeDesktop: number;
  fontSizeMobile: number;
  fontSizeMobileSmall: number;
  letterSpacing: number;
  lineHeight: number;
  lineHeightMobile: number;
  lineHeightMobileSmall: number;
}>(p =>
  adaptForLargeScreen({
    fontFamily: `"${p.font}"`,
    fontSize: p.fontSizeDesktop,
    marginBottom: 59,
    marginLeft: SITE_PADDING_LEFT,
    marginRight: 75,
    letterSpacing: p.letterSpacing,
    lineHeight: p.lineHeight,

    [mobileMQ]: {
      fontSize: p.fontSizeMobile,
      lineHeight: p.lineHeightMobile,
      marginLeft: SITE_PADDING_LEFT_MOBILE,
      marginRight: 20,
      marginBottom: 26,
    },

    [smallMobileMQ]: {
      fontSize: p.fontSizeMobileSmall,
      lineHeight: p.lineHeightMobileSmall,
    },

    [tinyMobileMQ]: {
      fontSize: p.fontSizeMobileSmall * 0.85,
    },

    p: {
      marginBottom: 0,
    },

    a: {
      textDecoration: 'underline',
    },
  }),
);

const PlayIcon = styled.div<{
  color: string;
  size: number;
  sizeMobile: number;
}>(p =>
  adaptForLargeScreen({
    width: 0,
    height: 0,
    display: 'inline-block',
    borderTopWidth: p.size / 2,
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    borderBottomWidth: p.size / 2,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderLeftWidth: p.size,
    borderLeftStyle: 'solid',
    borderLeftColor: p.color,
    marginRight: 15,

    [mobileMQ]: {
      borderTopWidth: p.sizeMobile / 2,
      borderBottomWidth: p.sizeMobile / 2,
      borderLeftWidth: p.sizeMobile,
    },

    [tinyMobileMQ]: {
      borderTopWidth: (p.sizeMobile * 0.75) / 2,
      borderBottomWidth: (p.sizeMobile * 0.75) / 2,
      borderLeftWidth: p.sizeMobile * 0.75,
    },
  }),
);

const ShowreelLink = styled.a<{ font: string }>(p =>
  adaptForLargeScreen({
    fontFamily: p.font,
    cursor: 'pointer',
  }),
);

const StyledImageLink = styled.a(
  adaptForLargeScreen({
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
  }),
);

const DraggableImageContainer = styled.div<{
  backgroundColor: string;
  originalWidth: number;
  originalHeight: number;
  contentWidth: string;
  contentHeight: string;
  top: number;
  left: number;
}>(p =>
  adaptForLargeScreen({
    position: 'absolute',
    top: p.top,
    left: p.left,
    width: p.contentWidth,
    height: p.contentHeight,
    backgroundColor: p.backgroundColor,
    cursor: 'grab',

    ':active': {
      cursor: 'grabbing',
    },

    [mobileMQ]: {
      width: p.originalWidth > p.originalHeight ? '100%' : '70%',
      height:
        (p.originalHeight / p.originalWidth) *
        (p.originalWidth > p.originalHeight ? 1 : 0.7) *
        window.innerWidth,
    },
  }),
);

const DraggableImage = styled(ResponsiveImage)(
  adaptForLargeScreen({
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    display: 'block',
  }),
);

const CloseButton = styled.div(
  adaptForLargeScreen({
    cursor: 'pointer',
    fontSize: 30,
    position: 'absolute',
    top: 16,
    right: 16,
    color: 'white',
    fontFamily: 'F37 Beckett Bold',
  }),
);

const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

type OpenImage = {
  image?: CmsHomepageImageEntry;
  videoUrl?: string;
  initialPosition: { x: number; y: number };
};

const getInitialPosition = (width: number, height: number) => {
  return {
    x: isMobile()
      ? width > height
        ? 0
        : randomIntFromInterval(0, (1 - 0.7) * window.innerWidth)
      : randomIntFromInterval(0, 1920 - width),
    y: randomIntFromInterval(
      isMobile() ? 70 : 100, // avoid the menu
      isMobile()
        ? window.innerHeight - (height / width) * window.innerWidth
        : 1920 * (window.innerHeight / window.innerWidth) - height,
    ),
  };
};

export const Home = (props: HomeProps) => {
  const [font, setFont] = useState(undefined as CmsHomepageFontEntry);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [openImages, setOpenImages] = useState([] as OpenImage[]);

  const { homepageFonts, siteText, images } = props;

  const setCurrentFont = () => {
    let fontIndex = Math.floor(Math.random() * homepageFonts.length);

    if (typeof window !== 'undefined') {
      const query = window.location.search;
      if (query.indexOf('font=') > -1) {
        fontIndex = parseInt(query.split('=')[1], 10) - 1;
      }
    }

    const font = homepageFonts[fontIndex];
    setFont(font);

    UIStore.setupPage(font.backgroundColour, font.foregroundColour);
  };

  useLayoutEffect(setCurrentFont, []);

  if (!font) return <div />;

  const getSiteText = getSiteTextFactory(siteText, 'Home');

  const openImage = (image: CmsHomepageImageEntry) => {
    const existingImage = openImages.find(x => x.image === image);
    if (existingImage) return bringToFront(existingImage);

    const initialPosition = getInitialPosition(
      IMAGE_SCALE * parseInt(image.width),
      IMAGE_SCALE * parseInt(image.height),
    );

    setOpenImages([...openImages, { image, initialPosition }]);
  };

  let imageLinkIndex = 0;
  const ImageLink: FC<{}> = props => {
    const index = imageLinkIndex;
    imageLinkIndex++;

    return (
      <StyledImageLink onClick={() => openImage(images[index])}>
        {props.children}
      </StyledImageLink>
    );
  };

  const closeImage = (image: OpenImage) => {
    setOpenImages(openImages.filter(x => x !== image));
  };

  const openVideo = () => {
    const videoUrl = `${CONTENT_URL}/uploads/video/Branding.mp4`;

    const existingVideo = openImages.find(x => x.videoUrl === videoUrl);
    if (existingVideo) return bringToFront(existingVideo);

    const initialPosition = getInitialPosition(1004, 565);

    setOpenImages([...openImages, { videoUrl, initialPosition }]);
  };

  const bringToFront = (image: OpenImage) => {
    setOpenImages([...openImages.filter(x => x !== image), image]);
  };

  const fontObservers = [font.fontName, `${font.fontName} Bold`].map(
    fontName => new FontFaceObserver(fontName),
  );

  Promise.all(fontObservers.map(f => f.load())).then(
    function() {
      setFontLoaded(true);
    },
    function() {
      setFontLoaded(true);
    },
  );

  if (!fontLoaded) return null;

  return (
    <Container id="f37-home-container">
      <FontDetails>
        Font:{' '}
        <a href={font.fontUrl} target="_blank">
          {font.fontName}
        </a>{' '}
        {font.fontCopyright}
      </FontDetails>
      <Text
        font={font.fontName}
        fontSizeDesktop={parseFloat(font.fontSizeDesktop)}
        fontSizeMobile={parseFloat(font.fontSizeMobile)}
        fontSizeMobileSmall={parseFloat(font.fontSizeMobileSmall)}
        letterSpacing={parseFloat(font.letterSpacing)}
        lineHeight={parseFloat(font.lineHeight)}
        lineHeightMobile={parseFloat(font.lineHeightMobile)}
        lineHeightMobileSmall={parseFloat(font.lineHeightMobileSmall)}
      >
        <StyledMarkdown
          source={getSiteText('Main Text')}
          renderers={{ delete: ImageLink }}
        />
        <ShowreelLink font={`${font.fontName} Bold`} onClick={openVideo}>
          <PlayIcon
            color={font.foregroundColour}
            size={parseFloat(font.fontSizeDesktop) / 1.5}
            sizeMobile={parseFloat(font.fontSizeMobile) / 1.5}
          />
          Play Showreel
        </ShowreelLink>
        {openImages.map(openImage => (
          <Draggable
            bounds="#f37-home-container"
            key={
              openImage.image ? openImage.image.image.path : openImage.videoUrl
            }
            onMouseDown={() => bringToFront(openImage)}
          >
            <DraggableImageContainer
              backgroundColor={
                openImage.image ? openImage.image.backgroundColour : '#fff'
              }
              top={openImage.initialPosition.y}
              left={openImage.initialPosition.x}
              originalWidth={
                openImage.image ? parseInt(openImage.image.width) : 1004
              }
              originalHeight={
                openImage.image ? parseInt(openImage.image.height) : 565
              }
              contentWidth={
                openImage.image
                  ? pxToVw(IMAGE_SCALE * parseInt(openImage.image.width))
                  : pxToVw(1004)
              }
              contentHeight={
                openImage.image
                  ? pxToVw(IMAGE_SCALE * parseInt(openImage.image.height))
                  : pxToVw(565)
              }
            >
              {openImage.image ? (
                <DraggableImage image={openImage.image.image} />
              ) : (
                <VideoPlayer url={openImage.videoUrl} />
              )}
              <CloseButton
                onTouchStart={e => e.stopPropagation()}
                onClick={() => closeImage(openImage)}
              >
                ×
              </CloseButton>
            </DraggableImageContainer>
          </Draggable>
        ))}
      </Text>
    </Container>
  );
};
